
@import 'color';

.mat-mdc-tooltip {
    background-color: $connect-primary !important;
    opacity: 1;
    font-size: 13px;
    padding: 5px 10px !important;
    max-width: 400px !important;
    box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.3);
    line-height: 20px;
    border-radius: 4px;
}

@import 'breakpoints';

.mat-mdc-tab-group {
    .mat-mdc-tab-body {
        padding: 30px;

        @include media-breakpoint('lt-md') {
            padding: 10px;
        }
    }
}

body .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
    min-width: 160px;
}
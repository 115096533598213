// Override default styles and center the snackbar
.cdk-overlay-container  {
    .cdk-global-overlay-wrapper {
        align-items: center !important;
        justify-content: center !important;
    }
}

.mat-mdc-snack-bar-container {

    &.alert-info {
        background-color: $light-grey;
    }

    &.alert-warning {
        background-color: $rag-amber;
    }
    
    &.alert-success {
        background-color: $rag-green;
    }
    
    &.alert-error {
        background-color: $rag-red;
    }

    .notification {
        display: flex;
        flex-direction: row;
        padding: 10px 0;

        .content {
            max-width: 85%;
            margin-right: 30px;

            label {
                font-size: 20px;
                font-weight: 600;
            }
    
            p {
                margin: 0;
            }
        }
    
        .mat-icon {
            &.type {
                font-size: 40px;
                margin-right: 15px;
                height: 40px;
                width: 40px;
            }
    
            &.close {
                position: absolute;
                right: 15px;
                top: 15px;
        
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

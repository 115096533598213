@import 'breakpoints';
@import 'color';
@import 'font';

app-dialog {
    height: 100%;
}

app-dialog.dialog-large-header-title .dialog-header-title {
    font-size: 32px;
}

.dialog-header {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

body .mat-mdc-dialog-container {
    border-radius: 0;
    box-shadow: none;
    background: none;
}

.dialog-form-label {
    @extend .font-size-l;
    @extend .font-weight-bold;
    display: inline-block;
    color: $connect-primary;
    margin-bottom: 8px;
    line-height: normal;
}

.mat-mdc-dialog-container {
    &:not(.right) {
        .mdc-dialog__surface {
            border-radius: 15px;
        }
    }

    /* MDC dialogs use transitions to animate opening, not a big problem if centred but when pulled to the right, 
       it looks broken for a split second. Therefore disabling transitions on all sub elements of right oriented dialogs. */
    &.right * {
        transition: none !important;
    }
}

.dialog-container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px;
    top: 0;
    left: 0;

    h3.dialog-header-title {
        font-size: 18px;
        padding: 20px 0px 20px 20px;
    }

    .dialog-content {
        min-height: 140px;

        [body] {
            height: 100%;
            min-height: 100%;
        }

        overflow: auto;
    }

    .dialog-close {
        margin-right: 5px;
    }

    .footer-wrapper {
        background-color: $gray-0;
    }

    [footer-actions] {
        width: 100%;
    }

    &:not(.right) {
        overflow: hidden;
        border-radius: 15px;

        .dialog-header {
            position: relative;
            padding: 16px 24px;
            background-color: transparent;
            z-index: 1;

            .dialog-header-title {
                color: $primary;
                font-size: 24px;
                font-weight: 700;
                padding: 0;
                background-color: transparent;
                height: auto !important;
            }
        }

        .footer-wrapper {
            [footer-actions] {
                display: flex;
                padding: 0;
                flex-direction: row;
                gap: 8px;
                justify-content: end;
            }
        }
    }

    &.right {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
        z-index: 999;
        max-width: 100vw;
        height: 100%;

        .dialog-state-wrapper {
            padding: 10% 0;
        }

        .dialog-header {
            border-radius: 0;
            height: auto;
            padding: 30px;
            background: none !important;
            z-index: 1;

            h3.dialog-header-title {
                font-size: 24px;
                font-weight: 700;
                padding: 0;
                color: $primary !important;
            }

            .dialog-close {
                text-align: center;
                padding: 0 !important;
                width: 30px;
                height: 30px;
                line-height: 20px;
                margin: 0;

                mat-icon {
                    margin: 0;
                }
            }
        }

        .dialog-content {
            position: static;
            border: 0;
            border-radius: 0;
            padding: 0 30px;

            section {
                border-bottom: 1px solid $grey-250;
                margin-bottom: 20px;
                padding-bottom: 20px;

                &:last-of-type {
                    border-bottom: 0;
                    margin-bottom: 0;
                }

                h3 {
                    margin-bottom: 20px;
                }
            }
        }

        .footer-wrapper {
            width: 100%;
            box-shadow: 0px -16px 24px 0px rgba(0, 0, 0, 0.06);
            padding: 30px;

            .footer-actions {
                padding: 0;
                flex-direction: column;
            }
        }
    }

    .close-button {
        border-style: solid;
        border-width: 1px;
        border-color: $gray-8;
    }

    .dialog-icon-wrapper {
        width: 148px;
        height: 148px;
        border-radius: 100%;
        border: 8px solid $rag-green;

        &.working {
            border-color: $rag-amber;
        }

        &.failed {
            border-color: $rag-red;
        }
    }
}

.dialog-container.dialog-dark-theme {
    background-color: transparent;

    .dialog-content {
        background-color: $white;
    }

    .dialog-header {
        background-color: $connect-primary;
        
        p {
            color: $white;
        }
    }
    
    .dialog-header-title {
        color: $white !important;
    }

    .close-button {
        background-color: $white;
        border-color: $white !important;
        outline-color: $white;
        color: $connect-primary;
    }
}



.dialog-full-screen {
    width: 100%;
    height: 100%;
}

.dialog-border-none {
    border-radius: 0 !important;
}

.dialog-navigation-wrapper {
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    
    a, button { 
        width: 100%;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        color: $gray-8;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;
    }

    a::after, button::after {
        content: "";
        float: left;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        margin-top: 16px;
        visibility: hidden;
        transform: scaleX(0);
        transition: all .2s ease-in-out;
    }

    a:hover::after, button:hover::after {
        visibility: visible;
        transform: scaleX(1);
    }

    button {
        padding: 0;
        border: 0;
        background-color: transparent;
        font-family: $font;
    }

    a:not(:first-of-type), button:not(:first-of-type) {
        padding: 0 10px;
    }

    a.active::after, a:hover::after, button.active::after, button:hover::after {
        transform: scaleX(1);
        visibility: visible;
        background-color: $connect-primary;
    }
}

.dialog-state-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 48px;
    gap: 24px;
}
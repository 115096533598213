@import 'font';

input[type=text],
input[type=phone],
input[type=number],
input[type=email],
input[type=tel],
input[type=date],
input[type=password],
input[type=file],
textarea {
    width: 100%;
    border: 0;
    padding: 0;
    background: transparent;
    height: auto;
    line-height: normal;
    box-shadow: none;
    font-family: $font;
    -webkit-appearance: none;
}

textarea {
    display: block;
    outline: none;
    max-height: 100px;
    height: auto;
    line-height: normal;
    padding-top: 10px;
}

// Hide HTML5 Up and Down arrows
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.readonly,
[readonly],
.disabled,
[disabled] {
    &:not(.disable-readonly-styles) {
        opacity: 0.9 !important;
        cursor: not-allowed !important;

        * {
            cursor: not-allowed !important;
        }
    }
}

.disable-selection {
    user-select: none;

    * {
        user-select: none;
    }
}
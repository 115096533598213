@mixin animate {
    -webkit-transition: all 0.2s; /* Safari prior 6.1 */
    transition: all 0.2s ease-out;
}

@mixin shadow {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@mixin checkered-background($size: 16px) {
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: $size $size;
    background-position: 0 0, 0 ($size*0.5), ($size*0.5) (-($size*0.5)), (-($size*0.5)) 0px;
}

@mixin hide-subscript-no-error {
    ::ng-deep .mat-mdc-form-field:not(.mat-form-field-invalid) {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

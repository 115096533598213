.form-field-small-wrapper {
    @include media-breakpoint('gt-xs') {
        max-width: 30%;
    }
}

.form-field-wrapper {
    @include media-breakpoint('gt-xs') {
        max-width: 40%;
    }
}

.form-field-wrapper-large {
    @include media-breakpoint('gt-xs') {
        max-width: 75%;
    }
}

.telephone-number-container {
    display: flex;
    gap: 24px;

    @include media-breakpoint('gt-xs') {
        max-width: 75%;
    }
}

.dialling-code-wrapper {
    flex: 1 1 20%;
}

.telephone-number-wrapper {
    flex: 1 1 40%;
}

.hint-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $connect-lightblue;
    border-radius: 8px;
    padding: 8px;
}

.action-radio-button {
    margin-bottom: 24px;
    border: 1px solid $connect-lightblue;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.action-radio-button:has(.action-radio-wrapper>[type="radio"]:checked) {
    @extend .background-gray-0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.action-radio-button>label {
    padding: 16px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
}

.action-radio-wrapper {
    position: relative;
    padding: 16px;
    height: 100%;
    display: flex;
    align-items: center;
}

.action-radio-wrapper>[type="radio"],
.action-radio-wrapper>[type="checkbox"] {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.action-radio-wrapper>[type="radio"]+span::after {
    content: "\f111";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
}

.action-radio-wrapper>[type="radio"]:checked+span::after {
    content: "\f058";
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: $connect-positive;
}

.action-radio-wrapper>[type="checkbox"]+span::after {
    content: "\f0c8";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
}

.action-radio-wrapper>[type="checkbox"]:checked+span::after {
    content: "\f14a";
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: $connect-positive;
}

.mat-mdc-option.sticky {
    @include font-weight-medium();
    background-color: $gray-0 !important;
    position: sticky;
    top: 0px;
    z-index:1000;
    border-bottom: 1px solid $connect-lightblue;
}

.show-first-error {
    .mat-mdc-form-field-error {
        display: none;
    }

    .mat-mdc-form-field-error:first-of-type {
        display: block;
    }
}

.form-field-error {
    margin-top: -1.5em;
}

@import 'breakpoints';
@import 'color';
@import 'font';

.table-wrapper {
    flex: 1 1 1e-9px;
    flex-direction: column;
    @extend .overflow-scroll;
    font-family: $font;

    *:not(.fa, mat-icon) {
        font-family: $font;
    }

    background-color: $white !important;

    .mat-mdc-table {
        border: none;
        background-color: $white !important;

        *:not(.fa, mat-icon) {
            font-size: 14px !important;
        }

        .mat-mdc-header-row {
            border: none;
            align-items: stretch;
            background-color: $gray-0 !important;

            .mat-mdc-header-cell {
                background-color: $gray-0 !important;
                border-left: none;
                border-right: none;
                border-top: 1px solid $gray-4;
                border-bottom: 1px solid $gray-4;
                min-height: 0;
                padding: 16px 4px;

                &:hover {
                    background-color: $gray-0 !important;
                }
            }

            @include text-gray-5;
            position: -webkit-sticky !important;
            position: sticky !important;
            top: 0;
            z-index: 2;
        }

        .mat-mdc-row {
            background-color: $white;
            border: none !important;
            align-items: stretch;

            .mat-mdc-cell {
                @include text-gray-8;
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 1px solid $connect-lightblue !important;
                padding: 24px 4px;
                background-color: inherit;

                &:first-of-type {
                    padding: 24px 16px;
                    border-left: 1px solid $connect-lightblue !important;
                }

                &:last-of-type {
                    border-right: 1px solid $connect-lightblue !important;
                }
            }
        }

        .mat-mdc-cell:first-of-type, .mat-mdc-header-cell:first-of-type, .mat-mdc-footer-cell:first-of-type {
            padding: 24px 16px;
        }

        .mat-mdc-checkbox {
            max-height: 16px !important;
            max-width: 16px !important;

            .mdc-checkbox {
                padding: 0;
            }

            .mdc-checkbox__background, .mdc-checkbox__native-control {
                width: 16px !important;
                height: 16px !important;
                top: 0;
                left: 0;
                border-width: 1px;
            }
        }

        .mat-mdc-header-cell, .mat-mdc-cell {
            min-height: auto;

            &.center {
                align-items: center;
                justify-content: center;
            }
        }
    }
    // Expandable table START
    .expandable-row {
        height: auto;
        min-height: 0;
    }

    .expandable-row-container {
        overflow: hidden;
        display: block;
        width: 100%;
    }

    .expandable-row-content {
        padding: 10px;
    }

    .expanded-row {
        border-bottom: 0;
    }
    // Expandable table END
}

.table-wrapper-compact {
    @extend .table-wrapper;

    .mat-mdc-table {
        *:not(.fa, mat-icon) {
            font-size: 12px !important;
        }
    }

    .mat-mdc-header-row .mat-mdc-header-cell {
        padding: 16px !important;
    }

    .mat-mdc-row .mat-mdc-cell {
        padding: 12px 16px !important;
    }
    
    .mat-mdc-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
        padding: 16px 16px !important
    }

    .mat-mdc-row.empty > td {
        padding: 12px 16px;
        font-size: 14px !important;
    }
}

.mat-mdc-paginator {
    background-color: $gray-0 !important;

    &.transparent {
        background-color: transparent !important;
    }

    font-size: 14px !important;

    * {
        font-size: 14px !important;
    }

    .mat-mdc-form-field-infix {
        padding: 0 0 !important;
        min-height: 0 !important;
    }

    .mat-mdc-paginator-page-size {
        margin-top: 0;
    }

    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
        font-family: $font;
    }
}
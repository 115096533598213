@import 'color';
@import 'font';
@import 'mixins';

.mat-mdc-checkbox,
.mat-mdc-card,
.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-list-item,
.mat-mdc-table,
.mat-mdc-tab-group {
    *:not(mat-icon, .fa, .fa-thin, .fa-light, .fa-regular, .fa-solid, .fa-brands) {
        font-family: $font;
    }
}

.mat-icon {
    color: inherit;
    margin-top: 2px;
}

.mat-error {
    color: var(--mdc-theme-error, $rag-red);
}

.mat-mdc-form-field {
    width: 100%;

    textarea.mat-mdc-input-element {
        line-height: normal;
    }

    .mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
        padding: 0;
    }

    &.mat-form-field-invalid {
        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: $rag-red !important;
        }
    }

    .mat-mdc-text-field-wrapper {
        padding-left: 12px;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        color: $gray-6;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 22px;
    }

    .mat-mdc-form-field-infix {
        padding: 11px 0 11px 0 !important;
        width: auto !important;
        border-top: 0;
        min-height: 47px;
    }

    &.mat-form-field-has-label .mat-mdc-form-field-infix {
        padding: 13px 0 !important;
        border-top: 0.34375em solid rgba(0, 0, 0, 0);
    }

    .mat-mdc-form-field-error-wrapper {
        .mat-mdc-form-field-error {
            font-size: 10px !important;
        }

        font-family: $font;
        font-size: 14px !important;
        top: -4px;
    }

    &.mat-focused:not(:disabled) .mdc-notched-outline {
        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: $gray-8;
        }
    }

    &:not(:disabled) {
        .mat-mdc-text-field-wrapper {
            background-color: $white;
        }

        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: $gray-8;
        }
    }

    &.mat-form-field-disabled {
        mat-label {
            color: $dark-grey;
        }
    }

    .mat-mdc-form-field-icon-suffix .mdc-icon-button {
        color: $gray-8;
    }

    &.no-padding {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.mat-mdc-tab-group {
    @extend .shadow;
    border-radius: 6px;
    background-color: $white;
    overflow: hidden;

    .mdc-tab__text-label {
        font-weight: 700;
        opacity: 1;
    }

    .mat-mdc-tab-disabled .mdc-tab__text-label {
        color: $dark-grey !important;
    }

    .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        border-top-width: 4px;
    }
}

.mat-mdc-checkbox {
    &.padded {
        .mdc-checkbox {
            margin-left: -4px;
        }

        .mdc-label {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    .mdc-checkbox__background, input {
        width: 32px !important;
        height: 32px !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        border-radius: 4px;
    }

    .mdc-label {
        word-spacing: normal !important;
        flex: 1;

        &:empty {
            display: none;
        }
    }

    &.checkbox-size-s, &.medium {
        .mdc-checkbox {
            padding: 6px;
        }

        .mdc-checkbox__background, input {
            width: 24px !important;
            height: 24px !important;
        }

        .mdc-label {
            @include font-size-s;
        }
    }

    &.checkbox-size-xs, &.small {
        .mdc-checkbox {
            padding: 4px;
        }

        .mdc-checkbox__background, input {
            width: 16px !important;
            height: 16px !important;
            border-width: 1px;
        }

        .mdc-label {
            @include font-size-xs;
        }
    }

    &.small-container {
        .mdc-checkbox {
            padding: 4px;
        }

        .mdc-checkbox__background, input {
            width: 16px !important;
            height: 16px !important;
            border-width: 1px;
        }
    }

    &.mdc-checkbox--disabled {
        .mdc-checkbox__background {
            background-color: $white !important;
            border-color: $gray-3 !important;

            .mdc-checkbox__mixedmark, .mdc-checkbox__checkmark {
                color: $gray-3 !important;
            }
        }
    }
}

.mat-mdc-option .mdc-list-item__primary-text {
    color: $connect-primary !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: $gray-3 !important;

    .mdc-list-item__primary-text,
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
    .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
        color: $connect-primary !important;
    }
}

.mat-form-field-date-picker button {
    position: relative;
}

.mat-mdc-slide-toggle span {
    font-family: "Inter", sans-serif;
}

/* Hiding icon on the slide-toggle using display style otherwise we'll have to add discrete attribute to all toggle elements. */
.mdc-switch__icons {
    display: none !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__track {
    &::after, &::before {
        background: rgba($rag-green,.38) !important;
    }
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: $rag-green !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: $white !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__track {
    &::after, &::before {
        background: #00000061 !important;
    }
}

.cdk-overlay-pane {
    max-width: 100%!important;

    @include media-breakpoint('lt-sm') {
        &:not(.mat-datepicker-popup) {
            position: fixed;   
            width: 100%!important;
            height: 100%!important;
        }    
    }
}

.mat-mdc-dialog-content {
    margin: 0!important;
}

.mat-mdc-dialog-container {
    padding: 0!important;
}
@include media-breakpoint('lt-sm') {
    .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
        &:not(.mat-datepicker-popup) {

            transform: none !important;
            left: 16px !important;
            right: 16px !important;
            bottom: 0 !important;
            width: auto !important;
            height: auto !important;
            max-height: 80% !important;

            .mat-mdc-select-panel {
                min-width: calc(100% - 30px) !important;
            }
        }
    }
}

.mat-mdc-option {
    font-family: $font !important;
}

.mat-form-field-placeholder {
    color: $gray-3 !important;
}

.mat-focused .mat-form-field-placeholder {
    color: $gray-3 !important;
}


.mdc-radio.mdc-radio--disabled {
    .mdc-radio__native-control {
        display: none !important;
    }

    padding: 0 10px !important;
}
.mat-mdc-radio-button:not(.mdc-radio--disabled) {
    .mdc-label {
        cursor: pointer;
    }
}


.radio-button mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $gray-3 !important;
}

.mat-mdc-radio-button.invalid {
    color: $connect-invalid;
}

.mdc-tooltip__surface {
    background-color: $connect-primary!important;
    color: $white !important;
    line-height: 18px !important;
    font-size: 14px !important;
}
